import React from 'react';
import './App.css';
import {MainForm} from './forms/MainForm';
import { DataProvider } from './DataProvider';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

export default
function App() {
    return (
        <div className="App">
            <header className="App-header">
            </header>
            <DataProvider>
                <Router>
                    <MainForm />
                </Router>
            </DataProvider>
        </div>
    );
}