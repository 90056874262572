import React from 'react';
import { PropTypes } from 'prop-types';

import {
  decimalHoursToTime,
  timeToDecimalHours,
  encodeDate,
  decodeDate,
} from '../util';


class SimpleInput extends React.PureComponent {
  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChangeValue: PropTypes.func,
    toView: PropTypes.func,
    fromView: PropTypes.func,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    style: PropTypes.object
  }

  _onChange = (e) => {
    this.props.onChangeValue
      && this.props.onChangeValue(e.target.name, this.props.fromView ? this.props.fromView(e.target.value) : e.target.value);
  }

  render() {
    const caption = this.props.caption === undefined ? this.props.name : this.props.caption;
    return (
      <div className="column" style={{padding: '5px',  ...this.props.style}}>
        {caption ? <div className="caption">{caption}</div> : null}
        <input
          className='Input'
          autoComplete={this.props.autoComplete}
          id={this.props.name}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          type={this.props.type}
          name={this.props.name}
          placeholder={this.props.placeHolder}
          value={(this.props.toView ? this.props.toView(this.props.value) : this.props.value) || ''}
          onChange={this._onChange}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}


export
class MultilineTextInput extends React.PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    onChangeValue: PropTypes.func,
    toView: PropTypes.func,
    fromView: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      value: (props.toView ? props.toView(props.value) : props.value) || ''
    }
  }

  _onChange = (e) => {
    try {
      this.props.onChangeValue
        && this.props.onChangeValue(e.target.name, this.props.fromView ? this.props.fromView(e.target.value) : e.target.value);
      this.setState({value: e.target.value, error: null});
    }
    catch (ex) {
      this.setState({error: ex, value: e.target.value})
    }
  }

  render() {
    const caption = this.props.caption === undefined ? this.props.name : this.props.caption;
    return (
      <div className="column" style={{padding: '5px',  ...this.props.style}}>
        {caption ? <div className="caption">{caption}</div> : null}
        <textarea
          className='MultilineInput'
          id={this.props.name}
          name={this.props.name}
          value={this.state.value}
          onChange={this._onChange}
          disabled={this.props.disabled}
          draggable={false}
        />
        {this.state.error ? <div style={{fontSize: 10}}>{this.state.error.message}</div> : null}
      </div>
    );
  }
}

export
const HiddenInput = (props) => (
  <SimpleInput
    fromView={String}
    type='hidden'
    {...props} />
);
HiddenInput.fieldName = 'hidden';

export
const TextInput = (props) => (
  <SimpleInput
    fromView={String}
    type='text'
    {...props} />
);
TextInput.fieldName = 'string';


export
const DateInput = (props) => (
  <SimpleInput
    toView={decodeDate}
    fromView={encodeDate}
    type='date'
    {...props}
  />
);
DateInput.fieldName = 'date';

export
const TimeInput = (props) => (
  <SimpleInput
    toView={decimalHoursToTime}
    fromView={timeToDecimalHours}
    type='time'
    {...props}
  />
);
TimeInput.fieldName = 'time';

export
const NumberInput = (props) => (
  <SimpleInput
    toView={(val) => val === null ? '' : String(val)}
    fromView={(val) => {
      if (val.length) {
        let num = Number(val.replace(',', '.'));
        num = props && isFinite(props.min) && num < props.min ? props.min : num;
        num = props && isFinite(props.max) && num > props.max ? props.min : num;
        return num;
      }
      return null;
    } }
    type='number'
    {...props}
  />
);
NumberInput.fieldName = 'number';

export
const RangeInput = (props) => (
  <div>
    <SimpleInput
      toView={(val) => val === null ? '' : String(val)}
      fromView={(val) => val.length ? Number(val) : null }
      type='range'
      {...props}
    />
    <div>{props.value}</div>
  </div>
);
RangeInput.fieldName = 'range';

export
const PasswordInput = (props) => (
  <SimpleInput
    fromView={String}
    type='password'
    {...props}
  />
);
PasswordInput.fieldName = 'password';

export
const EmailInput = (props) => (
  <SimpleInput
    fromView={(val) => val.length ? String(val) : null}
    type='email'
    {...props}
  />
);
EmailInput.fieldName = 'email';


export
class CheckboxInput extends React.PureComponent {
  static fieldName = 'checkbox'

  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    onChangeValue: PropTypes.func.isRequired,
    fromView: PropTypes.func,
    toView: PropTypes.func,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.fromView = props.fromView || ((val) => isFinite(val) ? Boolean(Number(val)) : Boolean(val));
    this.toView = props.toView || ((val) => Boolean(val));
  }

  _onChange = (e) => {
    const val = this.fromView(e.target.checked);
    this.props.onChangeValue && this.props.onChangeValue(e.target.name, val);
  }

  render() {
    const caption = this.props.caption === undefined ? this.props.name : this.props.caption;
    return (
      <div className="column" style={{padding: '5px',  ...this.props.style}}>
        {caption ? <div className="caption">{caption}</div> : null}
        <input
          type='checkbox'
          className='CheckboxInput'
          checked={this.toView(this.props.value)}
          id={this.props.name}
          name={this.props.name}
          onChange={this._onChange}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

const lookupFromView = (val) => {
  if (Number(val) > 0)
    return Number(val);
  else if (Number(val) === -1)
    return null;
  else if (Number(val) === -2)
    return undefined;
  return val;
};

export
class LookupInput extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hasNull: PropTypes.bool,
    nullCaption: PropTypes.string,
    toView: PropTypes.func,
    fromView: PropTypes.func,
    hasUndefinedOption: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.props = props || { fromView: lookupFromView };
  }

  _onChange = (e) => {
    const val = this.props.fromView ? this.props.fromView(e.target.value) : lookupFromView(e.target.value);
    this.props.onChangeValue && this.props.onChangeValue(e.target.name, val);
  }

  _renderOptions = () => {
    const options = [];
    if (this.props.hasUndefinedOption)
      options.push(<option key={'undefined'} value={-2}>Не задано</option>);
    if (this.props.hasNull)
      options.push(<option key={'null'} value={-1}>{this.props.nullCaption || 'null'}</option>);
    for (const item of this.props.items)
      options.push(<option key={item.value} value={item.value}>{item.caption}</option>);
    return options;
  }

  render() {
    const toView = this.props.toView || String;
    const caption = this.props.caption === undefined ? this.props.name : this.props.caption;
    return (
      <div className="column" style={{padding: '5px',  ...this.props.style}}>
        {caption ? <div className="caption">{caption}</div> : null}
        <select
          value={toView(this.props.value)}
          id={this.props.name}
          className={'LookupInput'}
          name={this.props.name}
          onChangeCapture={this._onChange}
          onChange={this._onChange}
          disabled={this.props.disabled}
        >
          {this._renderOptions()}
        </select>
      </div>
    );
  }
}