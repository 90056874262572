import React from 'react';
import { PropTypes } from 'prop-types';

import {TextInput, Icon, TextButton, NumberInput, MultilineTextInput, CheckboxInput, DateInput} from '../components';
import { UsersPane } from './UsersPane';
import { UsagePane } from './UsagePane';
import { ReportsPane } from './ReportsPane';

const MainFormPane = ({org, upd, onChange}) => (
    <div>
        <div className="caption h2">Контактная информация</div>
        <div className="row wrap">
            <TextInput name='name'  value={upd.name || org.name} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='email' value={upd.email || org.email} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='phone' value={upd.phone || org.phone} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='contact_person' value={upd.contact_person || org.contact_person} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='region' value={upd.region || org.region} onChangeValue={onChange} style={{width: '5%'}} />
        </div>
        <div className="caption h2">Тарификация</div>
        <div className="row wrap">
            <TextInput name='license_token' value={upd.license_token || org.license_token} onChangeValue={onChange} style={{flex: '1 0 400px', width: '50%'}} />
            <TextInput name='client_ip' value={upd.client_ip || org.client_ip} onChangeValue={onChange} style={{flex: '1 0 100px', width: '20%'}} />
            <NumberInput name='n_points_daily' value={upd.n_points_daily || org.n_points_daily} onChangeValue={onChange} style={{flex: '0 1 30px', width: '10%'}} />
            <NumberInput name='n_points_route' value={upd.n_points_route || org.n_points_route} onChangeValue={onChange} style={{flex: '0 1 30px', width: '10%'}} />
            <NumberInput name='n_routes_daily' value={upd.n_routes_daily || org.n_routes_daily} onChangeValue={onChange} style={{flex: '0 1 30px', width: '10%'}} />
            <DateInput name='subscription_expires' value={upd.subscription_expires || org.subscription_expires} onChangeValue={onChange} style={{flex: '0 1 100px', width: '20%'}} />
            <CheckboxInput name='blocked' value={upd.blocked != null ? upd.blocked : org.blocked} onChangeValue={onChange} style={{flex: '0 1 0%'}} />
            <CheckboxInput name='use_tracking' value={upd.use_tracking != null ? upd.use_tracking : org.use_tracking} onChangeValue={onChange} style={{flex: '0 1 0%'}} />
        </div>
        <div className="caption h2">Банковские реквизиты</div>
        <div className="row wrap">
            <TextInput name='companyname'  value={upd.companyname || org.companyname} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='companyfullname'  value={upd.companyfullname || org.companyfullname} onChangeValue={onChange} style={{flex: '1 1 0%'}} />            
            <TextInput name='inn' value={upd.inn || org.inn} onChangeValue={onChange} style={{flex: '1 1 0%'}} />            
            <TextInput name='kpp' value={upd.kpp || org.kpp} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='legaladdress' value={upd.legaladdress || org.legaladdress} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
            <TextInput name='companyphone' value={upd.companyphone || org.companyphone} onChangeValue={onChange} style={{flex: '1 1 0%'}} />
        </div>
        <div className="caption h2">Финансовые</div>
        <div className="row wrap">
            <NumberInput name='cost_ratio'  value={upd.cost_ratio || org.cost_ratio} onChangeValue={onChange} style={{flex: '0 1 30%', width: '10%'}} />
        </div>
    </div>
)

export
class OrganizationForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            changes: {},
            data: {},
            pane: "main"
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.id === parseInt(props.router.match.params["id"]))
            return null;

        return {
            id: parseInt(props.router.match.params["id"]),
            changes: {},
            data: {},
            pane: "main"
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.id === this.state.id || !this.state.id)
            return;

        const org = await this.props.actions.loadOrganization(this.state.id);
        const usage = await this.props.actions.loadUsageStats(org.license_token);
        this.setState({data: org, usage_data: usage});
    }

    async componentDidMount() {
        if (!this.state.id)
            return;

        const org = await this.props.actions.loadOrganization(this.state.id);
        const usage = await this.props.actions.loadUsageStats(org.license_token);
        this.setState({data: org, usage_data: usage});
    }

    _onInputChange = (name, value) => {
        this.setState({changes: {...this.state.changes, [name]: value}});
    }

    _renderPane = (org, upd) => {
        switch (this.state.pane) {
            case "main": return <MainFormPane org={org} upd={upd} onChange={this._onInputChange} />;
            case "ui_info": return <MultilineTextInput
                                        key={org.id}
                                        name="ui_info"
                                        value={upd.ui_info || org.ui_info}
                                        onChangeValue={this._onInputChange}
                                        toView={v => JSON.stringify(v, null, 4)}
                                        fromView={JSON.parse}
                                        style={{height: '100%', width: '98%'}} />
            case "users": return <UsersPane orgId={org.id} actions={this.props.actions} />
            case "usage": return <UsagePane data={this.state.usage_data} />
            case "reports": return <ReportsPane orgId={org.id} actions={this.props.actions} />
            default: return null;
        }
    }

    _saveChanges = async () => {
        const res = await this.props.actions.saveOrganization(this.state.id, this.state.changes);
        this.setState({data: res, changes: {}});
    }

    _undoChanges = () => {
        this.setState({changes: {}});
    }

    render() {
        const org = this.state.data;
        const upd = this.state.changes;

        return (
            <div className="column" style={{padding: '5px'}}>
                <div className="row">
                    {org.blocked ?
                        <Icon
                            svgName={'ItemSnippet_OutOfOrder'}
                            viewBox='0 0 1 1'
                            width='24px'
                            height='24px'
                            color='darkred' /> : null
                    }
                    <div>ID: {org.id}</div>
                    <div style={{width: '10px'}} />
                    <div>{org.name ? `${org.name} (${org.email})` : org.email}</div>
                    <div style={{width: '10px'}} />
                    {org.reg_date ? <div>Зарегистрирована: {org.reg_date}</div> : null}
                </div>
                <div className="row">
                    <div className="column">
                        <TextButton text={(this.state.pane == "main" ? ">" : "") + "Реквизиты"} onClick={() => this.setState({pane: "main"})} />
                        <TextButton text={(this.state.pane == "ui_info" ? ">" : "") + "ui_info"} onClick={() => this.setState({pane: "ui_info"})} />
                        <TextButton text={(this.state.pane == "users" ? ">" : "") + "Пользователи"} onClick={() => this.setState({pane: "users"})} />
                        <TextButton text={(this.state.pane == "reports" ? ">" : "") + "Отчёты"} onClick={() => this.setState({pane: "reports"})} />
                        <TextButton text={(this.state.pane == "usage" ? ">" : "") + "Статистика"} onClick={() => this.setState({pane: "usage"})} />
                        {Object.keys(this.state.changes).length !== 0 ?
                            <div className="row">
                                <TextButton icon=<Icon
                                        svgName={'WPointStatus_Ok'}
                                        style={{height: '24px', width: '24px'}}
                                        color='green'
                                    />
                                    onClick={this._saveChanges} />
                                <TextButton icon=<Icon
                                        svgName={'WPointStatus_Reject'}
                                        style={{height: '24px', width: '24px'}}
                                        color='darkred'
                                    />
                                    onClick={this._undoChanges} />
                            </div> : null
                        }
                    </div>
                    <div style={{height: '80vh', width: '100%'}}>
                        {this._renderPane(org, upd)}
                    </div>
                </div>
            </div>
        )
    }
}