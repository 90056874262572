import React from 'react';
import { PropTypes } from 'prop-types';

import { Switch, Route } from 'react-router-dom';

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import {TextInput, Icon, TextButton} from '../components';

const columns = [
    {Header: 'id', accessor: 'id', sortable: true, resizeable: true},
    {Header: 'last_msg', accessor: 'last_msg', sortable: true, resizeable: true},
    {Header: 'status', accessor: 'status', sortable: true, resizeable: true},
    {Header: 'task_id', accessor: 'task_id', sortable: true, resizeable: true},
    {Header: 'task_status', accessor: 'task_status', sortable: true, resizeable: true},
    {Header: 'progress', accessor: 'progress', sortable: true, resizeable: true}
];

export
class StatusForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            workers: [],
            tasks: [],
            yandex: null
        }
    }

    _updateQueueStatus = async () => {
        const res = await this.props.actions.getOptimizationQueueStatus();
        if (this.running) {
            this.setState({workers: res.workers, tasks: res.tasks});
            setTimeout(this._updateQueueStatus, 5000);
        }
    }

    _updateYandexStatus = async () => {
        const res = await this.props.actions.getYandexStatus();
        if (this.running) {
            this.setState({yandex: res});
            setTimeout(this._updateYandexStatus, 60000*5);
        }
    }

    _updateYandexStatusOnce = async () => {
        const res = await this.props.actions.getYandexStatus();
        if (this.running) {
            this.setState({yandex: res});
        }
    }

    componentDidMount() {
        this.running = true;
        this._updateQueueStatus();
        this._updateYandexStatus();
    }

    componentWillUnmount() {
        this.running = false;
    }

    render() {
        return (
            <div className="column">
                <div className="row" style={{alignItems: 'center'}}>
                    <div className="caption h2" style={{marginRight: '5px'}}>Яндекс:</div>
                    {
                        this.state.yandex === null ? 'Неизвестно'
                        :
                        this.state.yandex === true ? <span style={{fontWeight: 'bold', color: "green"}}>OK</span>
                        :
                        <span style={{fontWeight: 'bold', color: "darkred"}}>{`${this.state.yandex.status}: ${this.state.yandex.reason}`}</span>
                    }
                    <TextButton icon=<Icon
                            svgName={'Marker_Substitution'}
                            style={{height: '15px', width: '15px'}}
                        />
                        onClick={this._updateYandexStatusOnce}
                        style={{margin: '2px'}} />
                </div>
                <div className="row">
                    <div className="column" style={{flexBasis: '25%'}}>
                        <div className="caption h2">Очередь</div>
                        {this.state.tasks.map(t => <div className="caption h2">{t}</div>)}
                    </div>
                    <div className="column" style={{flexBasis: '100%'}}>
                        <div className="caption h2">Воркеры</div>
                        <ReactTable className="-striped -highlight" data={this.state.workers} columns={columns}
                            getTrProps={(state, rowInfo, column) => {
                                return (rowInfo && rowInfo.original.blocked) ? {style: {color: 'darkred'}} : {};
                            }}/>
                    </div>
                </div>
            </div>
        );
    }
}