export
const encodeDate = (value) => {
  try {
    const dmy = value.split('-');
    return new Date(Date.UTC(
      dmy[0],
      dmy[1] - 1,
      dmy[2]
    )).toUTCString();
  }
  catch (e) {
    new Date().toUTCString();
  }
};

export
const decodeDate = (date) => {
  if (!date)
    return null;

  const nDate = new Date(date);
  const d = `0${nDate.getDate()}`.slice(-2);
  const m = `0${nDate.getMonth() + 1}`.slice(-2);
  const y = `000${nDate.getFullYear()}`.slice(-4);
  return `${y}-${m}-${d}`;
};

export
const decimalHoursToTime = (hours) => {
  if (hours === null)
    return '';
  const h = Math.floor(hours);
  const m = Math.round(hours % 1 * 60);
  return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;
};

export
const timeToDecimalHours = (time) => {
  console.log(time);
  const [h, m] = time.split(":");
  if (isNaN(h) || isNaN(m))
    return null;
  return Number(h) + Number(m) / 60;
};