import React from 'react';
import { PropTypes } from 'prop-types';

import {TextInput, Icon, TextButton, NumberInput, MultilineTextInput, CheckboxInput, LookupInput} from '../components';

export
class ReportsPane extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reports: [],
            currentReport: null,
            changes: {},
            testParams: "[]",
            testFormat: "ods",
            testResult: ""
        }
    }

    async componentDidMount() {
        const reports = await this.props.actions.loadReports(this.props.orgId);
        this.setState({reports: reports || []});
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.orgId == this.props.orgId)
            return;

        const reports = await this.props.actions.loadReports(this.props.orgId);
        this.setState({reports: reports || []});
    }

    _getFieldValue = (name) => {
        if (this.state.changes.hasOwnProperty(name))
            return this.state.changes[name];
        if (this.state.currentReport !== null)
            return this.state.currentReport[name];
        return null;
    }

    _onInputChange = (name, value) => {
        this.setState({changes: {...this.state.changes, [name]: value}});
    }

    _findReport = (id) => {
        for (let i = 0; i < this.state.reports.length; ++i)
            if (this.state.reports[i].id === id)
                return this.state.reports[i];
        return null;
    }

    _saveChanges = async () => {
        if (this.state.currentReport !== null) {
            await this.props.actions.saveReport(this.state.currentReport.id, this.props.orgId, this.state.changes);
            const newReps = [...this.state.reports];
            for (let i = 0; i < newReps.length; ++i)
                if (newReps[i].id === this.state.currentReport.id) {
                    newReps[i] = {...newReps[i], ...this.state.changes};
                    break;
                }
            this.setState({reports: newReps, changes: {}});
        }
        else {
            const res = await this.props.actions.createReport(this.props.orgId, this.state.changes);
            const newReps = [...this.state.reports, {id: res, ...this.state.changes}];
            this.setState({reports: newReps, currentReport: newReps[newReps.length-1], changes: {}});
        }
    }

    _undoChanges = () => {
        this.setState({changes: {}});
    }

    _uploadTemplate = (e) => {
        const files = e.target.files;
        if (files.length === 0)
            return;

        const reader = new FileReader();
        reader.onload = () => {
            this.setState({changes: {...this.state.changes, template: reader.result.replace(/data:.*\/.*;base64,/, ''), filename: files[0].name}}) // remove data:*/*;base64,
        }
        reader.readAsDataURL(files[0]);
    }

    _downloadTemplate = async () => {
        const res = await this.props.actions.getReportTemplate(this.state.currentReport.id, this.props.orgId);
        if (! res)
            return;

        var element = document.createElement('a');
        element.setAttribute('href', 'data:application/octet-stream;base64,' + res);
        element.setAttribute('download', `${this.state.currentReport.id}.ods`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    _testQuery = async () => {
        try {
            const params = JSON.parse(this.state.testParams);
            const res = await this.props.actions.testReportQuery(this.state.currentReport.id, this.props.orgId, params);
            this.setState({testResult: JSON.stringify(res, null, 2)});
        }
        catch (e) {
            this.setState({testResult: `ERROR\n${e.message}`});
        }
    }

    _testReport = async () => {
        try {
            this.setState({testResult: ""});

            const params = JSON.parse(this.state.testParams);
            const res = await this.props.actions.testReport(this.state.currentReport.id, this.props.orgId, params, this.state.testFormat);
            if (!res) {
                this.setState({testResult: `EMPTY REPORT`});
                return;
            }
            var element = document.createElement('a');
            element.setAttribute('href', 'data:application/octet-stream;base64,' + res);
            element.setAttribute('download', `${this.state.currentReport.id}.${this.state.testFormat || 'ods'}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
        catch (e) {
            this.setState({testResult: `ERROR\n${e.message}`});
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <LookupInput
                        name="reportId"
                        caption=''
                        value={this.state.currentReport && this.state.currentReport.id}
                        items={this.state.reports.map(v => ({value: v.id, caption: v.name}))}
                        onChangeValue={(name, value) => {this.setState({currentReport: this._findReport(value), changes: {}})}}
                        hasNull={true}
                        nullCaption='Новый отчёт'
                    />
                    {Object.keys(this.state.changes).length != 0 ?
                        <>
                        <TextButton icon=<Icon
                                    svgName={'WPointStatus_Ok'}
                                    style={{height: '24px', width: '24px'}}
                                    color='green'
                                />
                                onClick={this._saveChanges} />
                        <TextButton icon=<Icon
                                svgName={'WPointStatus_Reject'}
                                style={{height: '24px', width: '24px'}}
                                color='darkred'
                            />
                            onClick={this._undoChanges} />
                        </>
                    : null}
                </div>
                <TextInput
                    name='name'
                    value={this._getFieldValue('name')}
                    onChangeValue={this._onInputChange}
                    style={{flex: '1 1 0%'}} />
                <div className="row">
                    <MultilineTextInput
                        key={this.state.currentReport && this.state.currentReport.id}
                        name="query"
                        value={this._getFieldValue('query')}
                        onChangeValue={this._onInputChange}
                        style={{width: '70%'}} />
                    <div className="column" style={{alignItems: 'self-start', flexBasis: '50%', maxWidth: '40%'}}>
                        <MultilineTextInput
                            key={this.state.currentReport && this.state.currentReport.id}
                            name="ui_info"
                            value={this._getFieldValue('ui_info')}
                            onChangeValue={this._onInputChange}
                            toView={v => JSON.stringify(v, null, 4)}
                            fromView={JSON.parse}
                            style={{width: '95%', height: '30vh'}} />
                        {this.state.currentReport != null ? <TextButton text="Скачать шаблон" onClick={this._downloadTemplate} /> : null}
                        <div className="row">
                            <TextButton text="Загрузить шаблон" onClick={() => document.getElementById('input-file').click()} />
                            <input type="file" id="input-file" style={{display: 'none'}}
                                onChange={this._uploadTemplate} />
                            <div className="caption h3">{this.state.changes.filename}</div>
                        </div>
                        {Object.keys(this.state.changes).length == 0 && this.state.currentReport != null ?
                            <>
                            <TextInput
                                name='test params (json list)'
                                value={this.state.testParams}
                                onChangeValue={(name, value) => this.setState({testParams: value})}
                                style={{width: '100%'}} />
                            <TextInput
                                name='test format'
                                value={this.state.testFormat}
                                onChangeValue={(name, value) => this.setState({testFormat: value})}
                                style={{width: '100%'}} />
                            <TextButton text="Проверить запрос" onClick={this._testQuery} />
                            <TextButton text="Проверить отчёт" onClick={this._testReport} />
                            </>
                        : null}
                        <pre id="json" style={{textAlign: 'left'}}>{this.state.testResult}</pre>
                    </div>
                </div>

            </div>
        )
    }
}