import React from 'react';
import { PropTypes } from 'prop-types';

import { Switch, Route } from 'react-router-dom';

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import {TextInput, Icon, TextButton} from '../components';
import { OrganizationForm } from './OrganizationForm';

class RegisterOrganizationModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    _onValueChanged = (name, value) => {
        this.setState({[name]: value})
    }

    _register = async () => {
        try {
            const org = await this.props.register(this.state);
            this.props.router.history.replace(`/organizations/${org.id}`);
        }
        catch (e) {
            this.setState({error: e.message});
        }
    }

    _cancel = async () => {
        this.props.router.history.goBack();
    }

    render = () => (
        <div className="modal">
            <div className="column">
                <TextInput name='name' value={this.state.name} onChangeValue={this._onValueChanged} />
                <TextInput name='email' value={this.state.email} onChangeValue={this._onValueChanged} />
                <TextInput name='phone' value={this.state.phone} onChangeValue={this._onValueChanged} />
                <TextInput name='password' value={this.state.password} onChangeValue={this._onValueChanged} />
                <div className="row">
                    <TextButton icon=<Icon
                            svgName={'WPointStatus_Ok'}
                            style={{height: '24px', width: '24px'}}
                            color='green'
                        />
                        onClick={this._register} />
                    <TextButton icon=<Icon
                            svgName={'WPointStatus_Reject'}
                            style={{height: '24px', width: '24px'}}
                            color='darkred'
                        />
                        onClick={this._cancel} />
                </div>
                {this.state.error ? <div style={{color: 'red'}}>{this.state.error}</div> : null}
            </div>
        </div>
    )
}

const columns = [
    {Header: '', accessor: 'id',
        Cell: props => <TextButton icon=<Icon
            svgName={'Item_Edit'}
            style={{height: '24px', width: '24px'}}/>
            to={`/organizations/${props.value}`}
        />,
        minWidth: 24
    },
    {Header: 'id', accessor: 'id', sortable: true, resizeable: true},
    {Header: 'name', accessor: 'name', sortable: true, resizeable: true},
    {Header: 'email', accessor: 'email', sortable: true, resizeable: true},
    {Header: 'phone', accessor: 'phone', sortable: false, resizeable: true},
    {Header: 'last_payment_date', accessor: 'last_payment_date', sortable: true, resizeable: true},
    {Header: 'reg_date', accessor: 'reg_date', sortable: true, resizeable: true},
    {Header: 'last_login', accessor: 'last_login', sortable: true, resizeable: true},
    {Header: 'last_use', accessor: 'last_use', sortable: true, resizeable: true},
    {Header: 'subscription_expires', accessor: 'subscription_expires', sortable: true, resizeable: true},
];

function filter(filter, values) {
    if (!filter || filter.trim() === '')
        return values;

    return values.filter(v => (
        String(v.id) === filter
        || (v.name && v.name.match(filter))
        || (v.email && v.email.match(filter))
        || (v.phone && v.phone.match(filter))
    ))
}

class OrganizationsTable extends React.PureComponent {
    static tableSorting = [];
    static tablePage = 0;

    constructor(props) {
        super(props);
        this.state = {
            tableData: props.data,
            filter: '',
            tableSorting: OrganizationsTable.tableSorting,
            tablePage: OrganizationsTable.tablePage
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {tableData: filter(state.filter, props.data)};
    }

    _onFilterValueChanged = (name, value) => {
        this.setState({filter: value})
    }

    render() {
        console.log(this.state.tableSorting);
        return <div className="column" style={{maxHeight: '50vh'}}>
            <div className="row" style={{flex:0.1, alignItems: 'center'}}>
                <TextButton
                    icon=<Icon svgName={'Marker_Substitution'} style={{height: '32px', width: '32px'}}/>
                    onClick={this.props.load} />
                <Icon svgName={'Tab_Zoom'} style={{height: '32px', width: '32px'}}/>
                <TextInput value={this.state.filter} onChangeValue={this._onFilterValueChanged} />
                <TextButton text="Зарегистрировать новую" to="/organizations/new"/>
            </div>
            <ReactTable className="-striped -highlight"
                data={this.state.tableData}
                columns={columns}
                sorted={this.state.tableSorting}
                onSortedChange={(newSorted, column, shiftKey) => {OrganizationsTable.tableSorting = newSorted; this.setState({tableSorting: newSorted})}}
                page={this.state.tablePage}
                onPageChange={(pageIndex) => {OrganizationsTable.tablePage = pageIndex; this.setState({tablePage: pageIndex})}}
                getTrProps={(state, rowInfo, column) => {
                    return (rowInfo && rowInfo.original.blocked) ? {style: {color: 'darkred'}} : {};
                }}
            />
         </div>
    }
}

export
class OrganizationsForm extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/organizations' render={props => <OrganizationsTable data={this.props.state.organizations} load={this.props.actions.loadOrganizations} />} />
                <Route path='/organizations/new' render={props => <RegisterOrganizationModal router={props} register={this.props.actions.registerOrganization} />} />
                <Route path='/organizations/:id' render={props => <OrganizationForm router={props} actions={this.props.actions} />} />
            </Switch>
        )
    }
}