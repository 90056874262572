import React from 'react';
import { PropTypes } from 'prop-types';

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import { withDataProvider, API_ROOT } from '../DataProvider';
import { LoginForm } from './LoginForm';
import {Icon, TextInput, PasswordInput, TextButton} from '../components';
import { OrganizationsForm } from './OrganizationsForm';
import { StatusForm } from './StatusForm';
import { UserSearchForm } from './UserSearchForm';


class AppHeader extends React.PureComponent {

    render() {
        return (
            <div className="AppHeader">
                <div className="column">
                    <Icon svgName={'ZigZag_Logo'} viewBox="0 0 10 5" />
                    <div style={{fontSize: 12}}>{API_ROOT}</div>
                </div>
                <div style={{borderLeft:"0.5px solid #545454", marginLeft: '5px', marginRight: '5px'}}></div>
                <div className="column" style={{textAlign: 'left', justifySelf: 'left', flex: '1 0.3 100%'}}>
                    <TextButton text='Статус' to='/' />
                    <TextButton text='Организации' to='/organizations' />
                    <TextButton text='Поиск пользователей' to='/users' />
                </div>
                <div style={{display: 'flex', flexGrow: 1}} />
                <div className="column" style={{alignSelf: 'left'}}>
                    <div>{this.props.username}</div>
                    <TextButton text='Выход' onClick={this.props.logout} />
                </div>
            </div>
        )
    }
}


class _MainForm extends React.PureComponent {

    async componentDidMount() {
        if (this.props.state.user && this.props.state.organizations.length === 0)
            this.props.actions.loadOrganizations();
    }

    async componentDidUpdate() {
        if (this.props.state.user && this.props.state.organizations.length === 0)
            this.props.actions.loadOrganizations();
    }

    render() {
        if (this.props.state.user)
            return (
                <>
                    <AppHeader username={this.props.state.user.email} logout={this.props.actions.logoutUser} />
                    <Switch>
                        <Route exact path='/' render={props => <StatusForm actions={this.props.actions} />} />
                        <Route path='/organizations' render={props => <OrganizationsForm state={this.props.state} actions={this.props.actions} />} />
                        <Route path='/users' render={props => <UserSearchForm actions={this.props.actions} />} />
                    </Switch>
                </>
            );
        else
            return <LoginForm state={this.props.state} actions={this.props.actions} />
    }

}

export const MainForm = withDataProvider(_MainForm);