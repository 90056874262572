import React from 'react';

export default
function Hint({ leftHint, rightHint }) {
  return <div>
    <div>
      <div>
        {leftHint || rightHint}
      </div>
    </div>
  </div>;
}
