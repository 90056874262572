import React from 'react';
import { PropTypes } from 'prop-types';


import {TextInput, Icon, TextButton, NumberInput, MultilineTextInput, CheckboxInput} from '../components';

class UserCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            data: props.user,
            changes: {},
            token: null
        }
    }

    _onInputChange = (name, value) => {
        this.setState({changes: {...this.state.changes, [name]: value}});
    }

    _undoChanges = () => {
        this.setState({changes: {}, editing: false})
    }

    _saveChanges = async () => {
        let user;
        if (this.props.user.id === -1) {
            await this.props.createUser(this.state.changes);
            this.setState({editing: false, data: {}, changes: {}, token: null});
        }
        else {
            user = await this.props.actions.saveUser(this.props.user.id, this.props.orgId, this.state.changes);
            this.setState({editing: false, data: user, changes: {}, token: null});
        }
    }

    _loginAs = async () => {
        const token = await this.props.actions.getUserAuthToken(this.props.user.id);
        this.setState({token: token});
    }

    _refreshToken = async () => {
        const token = await this.props.actions.refreshUserToken(this.props.user.id);
        this.setState({token: token});
    }

    render() {
        const upd = this.state.changes;
        const user = this.state.data;

        return (
            <div className="row">
                {!this.state.editing ?
                    <>
                        <TextButton
                            icon=<Icon
                                svgName={'Item_Edit'}
                                viewBox='0 0 1 1'
                                width='24px'
                                height='24px'
                            />
                            style={{height: 24, alignSelf: 'center'}}
                            onClick={() => this.setState({editing: true})} />
                        {this.props.user.id !== -1 ?
                            <TextButton
                                icon=<Icon
                                    svgName={'AppMenu_Accesses'}
                                    viewBox='0 0 1 1'
                                    width='24px'
                                    height='24px'
                                />
                                style={{height: 24, alignSelf: 'center'}}
                                onClick={this._loginAs} /> : null
                        }
                    </>
                    :
                    <>
                        <TextButton icon=<Icon
                                svgName={'WPointStatus_Ok'}
                                style={{height: '24px', width: '24px'}}
                                color='green'
                            />
                            style={{height: 24, alignSelf: 'center'}}
                            onClick={this._saveChanges} />
                        <TextButton icon=<Icon
                                svgName={'WPointStatus_Reject'}
                                style={{height: '24px', width: '24px'}}
                                color='darkred'
                            />
                            style={{height: 24, alignSelf: 'center'}}
                            onClick={this._undoChanges} />
                    </>
                }

                {user.blocked ?
                    <Icon
                        svgName={'ItemSnippet_OutOfOrder'}
                        viewBox='0 0 1 1'
                        width='24px'
                        style={{alignSelf: 'center'}}
                        color='darkred' /> : null
                }

                <div className="column">
                    <div className="row wrap">

                        <TextInput name='id'
                            disabled={true}
                            value={user.id === -1 ? 'Новый пользователь' : (upd.id || user.id)}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                        <TextInput name='name'
                            disabled={!this.state.editing}
                            value={upd.name || user.name}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                        <TextInput name='email'
                            disabled={!this.state.editing}
                            value={upd.email || user.email}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                        <TextInput name='password'
                            disabled={!this.state.editing}
                            value={upd.password || user.password}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                        <TextInput name='phone'
                            disabled={!this.state.editing}
                            value={upd.phone || user.phone}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                        <CheckboxInput name='driver'
                            disabled={!this.state.editing}
                            value={upd.driver != null ? upd.driver : user.driver}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                        <CheckboxInput name='blocked'
                            disabled={!this.state.editing}
                            value={upd.blocked != null ? upd.blocked : user.blocked}
                            onChangeValue={this._onInputChange}
                            style={{flex: '1 1 0%'}} />
                    </div>
                    {this.state.token ?
                        <div className="row">
                            <TextInput name='token'
                                disabled={true}
                                value={this.state.token}
                                style={{flex: '1 1 0%'}} />
                            <div style={{padding: '5px', marginLeft: '-11px'}}>
                                <div className="caption">refresh</div>
                                <div className='Input' style={{alignSelf: 'center'}}>
                                    <TextButton icon=<Icon
                                        svgName={'Marker_Substitution'}
                                    />
                                    onClick={this._refreshToken}
                                    style={{margin: '2px', height: '24px', width: '24px'}} />
                                </div>
                            </div>
                        </div> : null}
                    </div>
                </div>
        )
    }
}

export
class UsersPane extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: []
        }
    }

    async componentDidMount() {
        const users = await this.props.actions.loadUsers(this.props.orgId);
        this.setState({users: users || []});
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.orgId == this.props.orgId)
            return;

        const users = await this.props.actions.loadUsers(this.props.orgId);
        this.setState({users: users});
    }

    _createUser = async (data) => {
        const user = await this.props.actions.createUser(this.props.orgId, data);
        const newUsers = [...this.state.users, user];
        this.setState({users: newUsers});
        return user;
    }

    render() {
        return (
            <div>
                {this.state.users.map(u => <UserCard key={u.id} user={u} orgId={this.props.orgId} actions={this.props.actions} />)}
                <UserCard key={-1} user={{id: -1}} orgId={this.props.orgId} actions={this.props.actions} createUser={this._createUser} />
            </div>
        )
    }
}