import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from "react-router-dom";

import Icon from './Icon';
import Hint from './Hint';

export
const ButtonKinds = {
  WARNING: 'WARNING',
  DANGER: 'DANGER',
  UNIMPORTANT: 'UNIMPORTANT',
};


class _TextButton extends React.PureComponent {
    _onClick = (e) => {
        this.props.onClick && this.props.onClick(e);
        this.props.to && this.props.history.push(this.props.to);
    }

    render() {
        return (
            <div className="TextButton" onClick={this._onClick} style={this.props.style}>
                {this.props.text || this.props.icon}
            </div>
        )
    }
}

export
const TextButton = withRouter(_TextButton);


export
class Button extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    iconIsRight: PropTypes.bool,
    leftHint: PropTypes.string,
    rightHint: PropTypes.string,
    disabled: PropTypes.bool,
    toggledOff: PropTypes.bool,
    clickOnce: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
    route: PropTypes.func,
    children: PropTypes.any,
    kind: PropTypes.oneOf(Object.keys(ButtonKinds)),
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      toggledOff: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.disabled || false,
      toggledOff: nextProps.toggledOff || false,
    });
  }

  componentDidMount() {
    this.setState({
      disabled: this.props.disabled || false,
      toggledOff: this.props.toggledOff || false,
    });
  }

  _onClick = (e) => {
    if (!this.state.disabled) {
      this.props.onClick && this.props.onClick(e);
      this.props.to && this.props.route(this.props.to);
      this.setState({ disabled: this.props.clickOnce || false });
    }
  }

  _renderIcon() {
    if (this.props.icon)
      return (
        <div>
          <Icon svgName={ this.props.icon }/>
        </div>
      );
    return null;
  }

  _getStyleMod = () => ({
    'Default': !this.props.icon,
    'Iconed': Boolean(this.props.icon),
    'Disabled': !this.props.icon && this.props.disabled,
    'DisabledIconed': this.props.icon && this.props.disabled,
    'ToggledOff': !this.props.icon && this.props.toggledOff,
    'ToggledOffIconed': this.props.icon && this.props.toggledOff,
    'Warning': this.props.kind && this.props.kind === ButtonKinds.WARNING,
    'Danger': this.props.kind && this.props.kind === ButtonKinds.DANGER,
    'Unimportant': this.props.kind && this.props.kind === ButtonKinds.UNIMPORTANT,
  });

  render() {
    return (
      <div className="Button" onClick={this._onClick} style={this.props.style}>
        {!this.props.iconIsRight && this._renderIcon()}
        {this.props.children && <div>{this.props.children}</div>}
        {this.props.iconIsRight && this._renderIcon()}
        {(this.props.leftHint || this.props.rightHint) && <Hint {...this.props}/>}
      </div>
    );
  }
}
