import React from 'react';
import { PropTypes } from 'prop-types';

import { Switch, Route } from 'react-router-dom';

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import {TextInput, Icon, TextButton} from '../components';

const User = ({user}) => (
    <div className="row" style={{fontSize: '13px', justifyContent: 'space-between', textAlign: 'left'}}>
        <div className="row-item">
            <TextButton text={`${user.org_id} (${user.org_name})`} to={`/organizations/${user.org_id}`}/>
        </div>
        <div className="row-item">{user.id}</div>
        <div className="row-item">{user.name}</div>
        <div className="row-item">{user.email}</div>
        <div className="row-item">{user.phone}</div>
    </div>
)

export
class UserSearchForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            filter: ''
        }
        this.timeout = null;
    }

    _onFilterValueChanged = (name, value) => {
        this.setState({filter: value})
        if (this.timeout)
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this._update, 500);
    }

    _update = async () => {
        if (this.state.filter.length && this.state.filter.length > 2) {
            const res = await this.props.actions.findUsers(this.state.filter);
            this.setState({tableData: res || []});
        }
        this.timeout = null;
    }

    render() {
        return (
            <div className="column" style={{padding: '10px'}}>
                <div className="row" style={{flex:0.1, alignItems: 'center'}}>
                    <Icon svgName={'Tab_Zoom'} style={{height: '32px', width: '32px'}}/>
                    <TextInput value={this.state.filter} onChangeValue={this._onFilterValueChanged} />
                </div>
                <div className="row" style={{fontSize: '14px', fontWeight: 'bold', justifyContent: 'space-between', textAlign: 'left'}}>
                    <div className="row-item">org_id (org_name)</div>
                    <div className="row-item">id</div>
                    <div className="row-item">name</div>
                    <div className="row-item">email</div>
                    <div className="row-item">phone</div>
                </div>
                {this.state.tableData.map(u => <User key={u.id} user={u} />)}
            </div>
        )
    }
}
