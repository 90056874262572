import React from 'react';
import { PropTypes } from 'prop-types';

import {TextInput, Icon, TextButton, NumberInput, MultilineTextInput, CheckboxInput} from '../components';

import ReactTable from 'react-table'
import 'react-table/react-table.css'


const columns = [
    {Header: 'год', accessor: 'year', sortable: false, resizeable: true},
    {Header: 'месяц', accessor: 'month', sortable: false, resizeable: true},
    {Header: 'точек', accessor: 'points_count', sortable: true, resizeable: true},
    {Header: 'маршрутных листов', accessor: 'routes_count', sortable: true, resizeable: true},
];

export
class UsagePane extends React.PureComponent {
    render() {
        if (this.props.data.usage)
            return (
                <div className="column">
                    <div className="caption h2">Последний расчёт: {this.props.data.last_use}</div>
                    <div className="caption h2">{this.props.data.cur_month ?
                        `В этом месяце: ${this.props.data.cur_month.points_count} уникальных точек, ${this.props.data.cur_month.routes_count} маршрутных листов`
                        : 'Нет расчётов в этом месяце'}
                    </div>
                    <div className="caption h2">{this.props.data.totals ?
                        `Всего: ${this.props.data.totals.points_count} уникальных точек, ${this.props.data.totals.avg_points_count} точек в день`
                        : 'Нет расчётов'}
                    </div>
                    <div className="caption h2">Статистика по месяцам:</div>
                    <ReactTable className="-striped -highlight" data={this.props.data.usage} columns={columns} />
                </div>
            );
        return <div>Расчётов не производилось</div>;
    }
}