import React from 'react';
import { PropTypes } from 'prop-types';

import {Icon, TextInput, PasswordInput, TextButton} from '../components';
import { API_ROOT } from '../DataProvider';

export
class LoginForm extends React.PureComponent {
    static propTypes = {
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
        username: '',
        password: ''
        };
    }

    _onInputChange = (name, value) => {
        this.setState({
        [name]: value,
        });
    }

    _handleLogin = async () => {
        console.log(this.state);
        await this.props.actions.loginUser(this.state.username, this.state.password);
    }

    _onEnterPressed = (e) => {
        e.key === 'Enter' && this._handleLogin();
    }

    render() {
        return (
            <div
                className='LoginForm-inputs'
                onKeyPress={this._onEnterPressed}
            >
                <Icon svgName={'ZigZag_Logo'} viewBox="0 0 10 5"/>
                {API_ROOT}
                <TextInput
                    placeHolder='Пользователь'
                    name='username'
                    value={this.state.username}
                    onChangeValue={this._onInputChange}
                />
                <PasswordInput
                    placeHolder='Пароль'
                    name={'password'}
                    value={this.state.password}
                    onChangeValue={this._onInputChange}
                />
                <TextButton onClick={this._handleLogin} style={{width: '100%'}} text='Войти' />
            </div>
        )
    }
}
